import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoaderComponent from './components/route-loader.component';
import './App.css';
const AdminRouter = lazy(() => import('./components/admin.route'));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<LoaderComponent />}>
          <Route path="/">
            <AdminRouter />
          </Route>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
